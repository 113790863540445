import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const tabStyles = {
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    minHeight: 0,
    minWidth: 0,
    flex: "none",
  },
  containerWithScrollControls: {
    position: "relative",
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "1rem",
  },
  tabsWithScrollControls: {
    scrollBehavior: "smooth",
  },
  tabContainer: {
    padding: "0 10px",
    display: "flex",

    ":focus": {
      outline: "none",
      textShadow: "0 2px 2px rgba(0,0,0,0.25)",
    },
  },
  tab: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "0.8125em",
    fontWeight: 600,
    color: colours.oldSecondary,
    paddingBottom: 10,
    borderBottom: "2px transparent solid",
    cursor: "pointer",
  },
  firstTab: {},
  lastTab: {},
  currentTabContainer: {},
  currentTab: {
    borderBottomColor: colours.primary,
  },
  tabContent: {
    display: "flex",
    flex: "1 1 100%",
  },
  tabContentHidden: {
    display: "none",
  },
  tabBadge: {
    ...gStyles.fontSemiBold,
    height: ".875rem",
    display: "flex",
    alignItems: "center",
    fontSize: ".625rem",
    padding: "0 .2rem",
    marginLeft: ".625rem",
    color: "#fff",
    backgroundColor: "var(--color-secondary-d4)",
    width: "auto",
    borderRadius: 3,
    textDecoration: "none",
  },
  tabBadgeBefore: {
    marginLeft: 0,
    marginRight: ".625rem",
  },
  proBadge: {
    marginLeft: "0.5rem",
  },
};

export default tabStyles;
