import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import {
  memo,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

import useLayoutEffect from "hooks/useLayoutEffect";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  scrollControlButton: {
    position: "absolute",
    color: colours.black,
    fontSize: "1rem",
    pointerEvents: "none",
    top: 0,
    display: "flex",
    flexDirection: "row",
    zIndex: 3,
    alignItems: "center",
    [ScreenSizes.lgAndAbove]: {
      alignItems: "flex-start",
    },
  },
  scrollControlButtonFixed: {
    position: "fixed",
  },
  scrollControlButtonInner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "auto",
    cursor: "pointer",
    backgroundColor: colours.white,
    border: "1px solid #ddd",
    boxShadow: "2px 2px 3px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: 4,
    width: "1.75em",
    height: "2em",
  },
  scrollControlButtonLeft: {
    left: ".5rem",
  },
  scrollControlButtonRight: {
    right: ".5rem",
  },
};

const getShowArrowLeft = (scrollElement) => {
  if (scrollElement) {
    return scrollElement && scrollElement.scrollLeft > 0;
  }

  return false;
};

const getShowArrowRight = (scrollElement) => {
  if (scrollElement) {
    return (
      scrollElement.scrollLeft + scrollElement.clientWidth <
      scrollElement.scrollWidth
    );
  }

  return false;
};

const ScrollControlButtons = (props) => {
  const { scrollRef, positionFixed } = props;

  const location = useLocation();
  const { pathname } = location;

  const { styles } = useStyles(baseStyles, props);
  const scrollElement = useMemo(
    () => scrollRef && scrollRef.current,
    [scrollRef]
  );

  const [showLeftArrow, setShowLeftArrow] = useState(
    getShowArrowLeft(scrollElement)
  );
  const [showRightArrow, setShowRightArrow] = useState(
    getShowArrowRight(scrollElement)
  );
  const scrollElementHasMounted = !!scrollElement;

  useEffect(() => {
    if (scrollElement) {
      scrollElement.scrollLeft = 0;
      setShowLeftArrow(false);
      setShowRightArrow(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useLayoutEffect(() => {
    setShowLeftArrow(getShowArrowLeft(scrollElement));
    setShowRightArrow(getShowArrowRight(scrollElement));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollElementHasMounted]);

  const arrowStyle = useMemo(
    () => ({
      height: scrollElement ? scrollElement.clientHeight : 0,
    }),
    [scrollElement]
  );

  const contentIsBiggerThanContainer =
    scrollElement && scrollElement.scrollWidth > scrollElement.clientWidth;

  /*
  const handleScrollRelease = useCallback(() => {
    clearTimeout(scrollTimeoutRef.current);
    beingScrolledRef.current = false;
    timeSinceLastScrollRef.current = null;
  }, []);
  */

  const handleScrollClick = useCallback(
    (isLeft = false) =>
      () => {
        // The above is commented out for the moment
        // but if the component is used anywhere other than the
        // podcast page hold down scrolling should be fixed
        scrollElement.scrollLeft = isLeft
          ? 0
          : scrollElement.scrollWidth - scrollElement.clientWidth;

        if (isLeft) {
          setShowLeftArrow(false);
          setShowRightArrow(true);
        } else {
          setShowLeftArrow(true);
          setShowRightArrow(false);
        }
      },
    [scrollElement]
  );

  const renderScrollButton = (icon, isLeft = false) => (
    <div
      className={css(
        styles.scrollControlButton,
        positionFixed && styles.scrollControlButtonFixed,
        isLeft
          ? styles.scrollControlButtonLeft
          : styles.scrollControlButtonRight
      )}
      style={arrowStyle}
    >
      <div
        data-id={`scroll-control-${isLeft ? "left" : "right"}-button`}
        className={css(styles.scrollControlButtonInner)}
        onClick={handleScrollClick(isLeft, true)}
      >
        <FontAwesomeIcon icon={icon} />
      </div>
    </div>
  );

  if (contentIsBiggerThanContainer) {
    return (
      <Fragment>
        {showLeftArrow && renderScrollButton(faAngleLeft, true)}
        {showRightArrow && renderScrollButton(faAngleRight)}
      </Fragment>
    );
  }

  return null;
};

ScrollControlButtons.propTypes = {
  scrollRef: PropTypes.object,
  positionFixed: PropTypes.bool,
  scrollPeriod: PropTypes.number,
};

ScrollControlButtons.defaultProps = {
  scrollRef: null,
  positionFixed: false,
  scrollPeriod: 300,
};

export default memo(ScrollControlButtons);
